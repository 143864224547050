/* eslint-disable no-unused-vars */
import Vue from "vue";
import Vuetify from "vuetify/lib/framework";
import "@fortawesome/fontawesome-free/css/all.css";
import colors from 'vuetify/lib/util/colors'

import VueSweetalert2 from 'vue-sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';

const vuetify = new Vuetify({
  //theme: { disable: true },
  theme: {
    themes: {
      light: {
        primary: colors.lightBlue.darken1, // #E53935
        secondary: colors.orange.lighten4, // #FFCDD2
        accent: colors.indigo.base, // #3F51B5
      },
      dark: {
        primary: colors.lightBlue.darken1, // #E53935
        secondary: colors.orange.lighten4, // #FFCDD2
        accent: colors.indigo.base, // #3F51B5
      }
    }
  },
  icons: {
    iconfont: "fa",
  },
});

//Vue.use(vuelidate.default)
Vue.use(Vuetify);
Vue.use(VueSweetalert2);

export default new Vuetify({
  rtl: false,
});

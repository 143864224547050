/* eslint-disable no-undef */
import http, { eauth, e } from './http'
//import * as JWT from 'jwt-decode'
import jwt_decode from "jwt-decode";

const STORAGE_NAME = 'hkcuser'

const loginService = {
  login: async function (username, password) {
    const formBody = []
    formBody.push(encodeURIComponent('grant_type') + '=' + encodeURIComponent('password'))
    formBody.push(encodeURIComponent('client_id') + '=' + encodeURIComponent(process.env.VUE_APP_CLIENT_ID))
    formBody.push(encodeURIComponent('client_secret') + '=' + encodeURIComponent(process.env.VUE_APP_CLIENT_SECRET))
    formBody.push(encodeURIComponent('username') + '=' + encodeURIComponent(username))
    formBody.push(encodeURIComponent('password') + '=' + encodeURIComponent(password))
    
    const body = formBody.join('&')

    const headers = {
      'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
    }

    try {
      const response = await http.post(eauth('connect/token'), body, headers)

      const hasAccessToken = !!response.data.access_token

      if (hasAccessToken) {
        // console.log(JWT(response.data.access_token))

        localStorage.setItem(STORAGE_NAME, JSON.stringify(response.data))

        return {
          success: true,
          message: '',
        }
      }

      return {
        success: false,
      }
    } catch (error) {
      return {
        success: false,
        message: error.error_description,
      }
    }
  },
  requestRestorePassword (username) {
    return http.put(e(`User/RequestRestorePassword/${username}`), null, {}).then(r => {
      return r.data
    })
  },
  restorePassword (data) {
    return http.put(e('User/RestorePassword'), data, {}).then(r => {
      return r.data
    })
  },
  getImage () {
    return http.get(e(`User/Image?timestamp=${new Date().getTime()}`), null, {}).then(d => {
      return d.data
    })
  },
  // eslint-disable-next-line no-unused-vars
  isJwtAlive: function (jwt) {
    // const now = Date.now().valueOf() / 1000

    // if (typeof jwt.exp !== 'undefined' && jwt.exp < now) return false
    // if (typeof jwt.nbf !== 'undefined' && jwt.nbf > now) return false

    return true
  },
  // eslint-disable-next-line no-unused-vars
  isAuthenticated: function (redirect = true) {
    var token = localStorage.getItem(STORAGE_NAME)
    if (token == null) { return false }

    var jwt = this.getSessionToken()

    return this.isJwtAlive(jwt)
  },
  getRawToken: function (redirect = true) {
    var token = localStorage.getItem(STORAGE_NAME)
    if (token) {
      var parsedToken = JSON.parse(token)
      return parsedToken.access_token
    }

    if (redirect) { location.href = '/' }

    return null
  },
  getSessionToken: function (redirect = true) {
    var token = localStorage.getItem(STORAGE_NAME)
    if (token) {
      var parsedToken = JSON.parse(token)
      return jwt_decode(parsedToken.access_token)
    }

    if (redirect) { location.href = '/' }

    return null
  },
  logout () {
    localStorage.removeItem(STORAGE_NAME)
    localStorage.clear()

    location.href = '/'
  },
  getUserData () {
    var jwt = this.getSessionToken()
    return jwt;
  },
  isAdmin () {
    const data = this.getUserData()
    return [].concat(data['role']).indexOf('admin') !== -1;
  },
}

export default loginService

import http, { e } from './http'
import loginService from './login.service'

const noticeService = {
  getNotices: function (data){
    const token = loginService.getRawToken()

    const headers = {
      Authorization: 'Bearer ' + token,
    };

    return http.get(e('app/notice'), data, headers).then(r => {
      return r.data
    })
  },  
  getNotice: function (noticeId){
    const token = loginService.getRawToken()

    const headers = {
      Authorization: 'Bearer ' + token,
    };

    return http.get(e(`app/notice/${noticeId}`), null, headers).then(r => {
      return r.data
    })
  }
}

export default noticeService

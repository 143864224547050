<template>
  <v-app>
    <v-main class="auth-pages">
      <div
          :style="`background-image:  url('https://matteldev.blob.core.windows.net/hkc/cris-ovalle-YTk8tO3PGdA-unsplash.jpg'); background-size: cover; background-position: center;`"
          class="
          header-auth
          position-relative
          ma-4
          pb-16
          pt-16
          border-radius-xl
          min-height-300
        "
      >
        <span
            class="mask bg-gradient-default border-radius-xl opacity-4"
        ></span>
        <v-img
            v-if="this.$route.name == 'Pricing'"
            alt="pattern-lines"
            class="position-absolute opacity-6 start-0 top-0 w-100"
            src="@/assets/img/shapes/waves-white.svg"
        ></v-img>
        <v-container>
          <v-row class="d-flex mt-5">
            <v-col class="mx-auto py-0 position-relative" cols="12" md="6">
              <h3
                  v-if="this.$route.name == 'Pricing'"
                  class="text-h3 font-weight-bold text-white text-center mb-2"
              >
                {{ headerTitle() }}
              </h3>
              <h1
                  v-else
                  class="
                  text-h1 text-white
                  font-weight-bolder
                  text-center
                  mb-2
                  mt-5
                "
              >
                {{ headerTitle() }}
              </h1>
              <p
                  class="
                  text-white
                  font-size-root
                  text-center
                  font-weight-thin
                  mb-12
                "
              >
                {{ paragraphs }}
              </p>
            </v-col>
          </v-row>
        </v-container>
      </div>
      <fade-transition :duration="200" mode="out-in" origin="center top">
        <!-- your content here -->
        <v-container class="mt-n16 pb-0">
          <router-view></router-view>
          <content-footer v-if="!$route.meta.hideFooter" auth></content-footer>
        </v-container>
      </fade-transition>
    </v-main>
  </v-app>
</template>
<script>
import {FadeTransition} from "vue2-transitions";
import ContentFooter from "@/components/Footer.vue";

export default {
  name: "page-layout",
  components: {
    FadeTransition,
    ContentFooter,
  },
  data() {
    return {
      paragraphs: "",
      tab: null,
    };
  },
  methods: {
    headerTitle() {
      switch (this.$route.name) {
        case "SignUpBasic":
          this.paragraphs =
              "Use these awesome forms to login or create new account in your project for free.";
          return "Welcome!";
        default:
          break;
      }
    },
  },
};
</script>

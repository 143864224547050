<!-- eslint-disable vue/no-use-v-if-with-v-for -->
<template>
  <v-navigation-drawer
      :class="!$vuetify.breakpoint.mobile ? '' : 'bg-white'"
      :data-color="sidebarColor"
      :data-theme="sidebarTheme"
      :expand-on-hover="mini"
      :right="$vuetify.rtl"
      :value="drawer"
      app
      class="my-4 ms-4 border-radius-xl"
      fixed
      floating
      height="calc(100% - 2rem)"
      width="100%"
  >
    <v-list-item class="pa-0">
      <v-list-item-content class="pa-0">
        <v-list-item-title class="title d-flex align-center mb-0">
          <div class="v-navigation-drawer-brand pa-5 d-flex align-center">
            <v-img
                class="navbar-brand-img ms-3"
                src="@/assets/img/logos/logo.png"
                width="32"
            >
            </v-img>
            <span class="ms-2 font-weight-bold text-sm"
            >HKC Management</span
            >
          </div>
        </v-list-item-title>
      </v-list-item-content>
    </v-list-item>

    <hr
        :class="sidebarTheme == 'dark' ? 'light' : 'dark'"
        class="horizontal mb-0"
    />

    <v-list dense nav>
      <v-list-group
          :ripple="false"
          active-class="item-active"
          append-icon="fas fa-angle-down"
          class="pb-1 mx-2"
      >
        <template v-slot:activator>
          <v-avatar class="my-3 ms-2" size="30">
            <img alt="Brooklyn" src="@/assets/img/user.png"/>
          </v-avatar>

          <v-list-item-content>
            <v-list-item-title class="ms-2 ps-1 font-weight-light">
              {{user.email}}
            </v-list-item-title>
          </v-list-item-content>
        </template>

        <v-list-item
            v-for="child in userInfo"
            :key="child.title"
            :ripple="false"
            class="mb-1 no-default-hover py-2"
            link
            @click="userOptionClick(child)"
        >
          <span
              class="v-list-item-mini ms-0 font-weight-light text-center w-20"
              v-text="child.prefix"
          ></span>

          <v-list-item-content v-if="!child.items" class="ms-2 ps-1">
            <v-list-item-title v-text="child.title"></v-list-item-title>
          </v-list-item-content>

          <v-list-item-content v-if="child.items" class="ms-1 ps-1 py-0">
            <v-list-group
                v-model="child.active"
                :ripple="false"
                no-action
                prepend-icon=""
                sub-group
            >
              <template v-slot:activator>
                <v-list class="pa-0" dense nav>
                  <v-list-group
                      :ripple="false"
                      active-class="item-active"
                      append-icon="fas fa-angle-down me-auto ms-1"
                      class="mb-0"
                  >
                    <template v-slot:activator class="mb-0">
                      <v-list-item-content class="py-0">
                        <v-list-item-title
                            v-text="child.title"
                        ></v-list-item-title>
                      </v-list-item-content>
                    </template>
                  </v-list-group>
                </v-list>
              </template>

              <v-list-item
                  v-for="child2 in child.items"
                  :key="child2.title"
                  :ripple="false"
                  :to="child2.link"
                  @click="listClose($event)"
              >
                <span class="v-list-item-mini" v-text="child2.prefix"></span>
                <v-list-item-content>
                  <v-list-item-title v-text="child2.title"></v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list-group>
          </v-list-item-content>
        </v-list-item>
      </v-list-group>
    </v-list>

    <hr
        :class="sidebarTheme == 'dark' ? 'light' : 'dark'"
        class="horizontal mb-3"
    />
    
    <v-list dense nav>
      <v-list-item-group>
        <div v-for="(item, i) in itemsMenu" :key="i">
          <v-list-item
              :ripple="false"
              :to="item.link"
              active-class="item-active"
              class="pb-1 mx-2 no-default-hover py-2"
              link
          >
            <v-list-item-icon class="me-2 align-center">
              <i class="material-icons-round opacity-10">{{ item.action }}</i>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title
                  class="ms-1"
                  v-text="item.title"
              ></v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </div>
      </v-list-item-group>

      <hr
          class="horizontal my-4"
          :class="sidebarTheme == 'dark' ? 'light' : 'dark'"
      />
    <!-- eslint-disable vue/no-use-v-if-with-v-for -->
    <v-list-group
        v-if="isAdmin"
        :ripple="false"
        v-for="item in itemsAdmin"
        :key="item.title"
        v-model="item.active"
        append-icon="fas fa-angle-down"
        class="pb-1 mx-2"
        active-class="item-active"
    >
      <template v-slot:activator>
        <v-list-item-icon class="me-2 align-center">
          <i class="material-icons-round opacity-10">{{ item.action }}</i>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title
              v-text="item.title"
              class="ms-1"
          ></v-list-item-title>
        </v-list-item-content>
      </template>

      <v-list-item
          :ripple="false"
          link
          class="mb-1 no-default-hover px-0"
          :class="child.items ? 'has-children' : ''"
          v-for="child in item.items"
          :key="child.title"
          :to="child.link"
      >
        <v-list-item-content class="ps-4" v-if="!child.items">
            <v-list-item-title
                class="ms-6"
                v-text="child.title"
                @click="listClose($event)"
            ></v-list-item-title>
        </v-list-item-content>

        <v-list-item-content class="py-0" v-if="child.items">
          <v-list-group
              prepend-icon=""
              :ripple="false"
              sub-group
              no-action
              v-model="child.active"
          >
            <template v-slot:activator>
              <v-list nav dense class="py-2 ps-5 pe-2">
                <v-list-group
                    :ripple="false"
                    append-icon="fas fa-angle-down me-auto ms-1"
                    active-class="item-active"
                    class="mb-0"
                >
                  <template v-slot:activator class="mb-0">
                    <div class="w-100 d-flex align-center">
                        <span
                            class="v-list-item-mini ms-1"
                            v-text="child.prefix"
                        ></span>

                      <v-list-item-content class="py-0 ms-4">
                        <v-list-item-title
                            class="ms-2"
                            v-text="child.title"
                        ></v-list-item-title>
                      </v-list-item-content>
                    </div>
                  </template>
                </v-list-group>
              </v-list>
            </template>

            <v-list-item
                v-for="child2 in child.items"
                :ripple="false"
                :key="child2.title"
                :to="child2.link"
                @click="listClose($event)"
                class="px-0"
            >
              <v-list-item-content>
                <div class="d-flex align-items-center pa-2">
                    <span
                        class="v-list-item-mini"
                        v-text="child2.prefix"
                    ></span>
                  <v-list-item-title
                      v-text="child2.title"
                      class="ms-6"
                  ></v-list-item-title>
                </div>
              </v-list-item-content>
            </v-list-item>
          </v-list-group>
        </v-list-item-content>
      </v-list-item>
    </v-list-group>
    </v-list>

    <v-list-item style="position: fixed !important;bottom: 0 !important;width: 100%;" @click="logout()">
      <v-list-item-icon class="mt-7">
        <i class="material-icons-round">power_settings_new</i>
      </v-list-item-icon>
      <v-list-item-content>Salir</v-list-item-content>
    </v-list-item>
    
    <v-card
        :style="`background-image: url(${require('../assets/img/curved-images/white-curved.jpeg')}); background-size: cover;`"
        class="pa-0 border-radius-lg mt-7 mb-9 mx-4"
    >
      <span
          :class="`bg-gradient-` + sidebarColor"
          class="mask opacity-8 border-radius-lg"
      ></span>
    </v-card>
  </v-navigation-drawer>
</template>
<script>
import loginService from "@/services/login.service";

export default {
  name: "drawer",
  props: {
    drawer: {
      type: Boolean,
      default: null,
    },
    sidebarColor: {
      type: String,
      default: "success",
    },
    sidebarTheme: {
      type: String,
      default: "dark",
    },
  },
  data: () => ({
    mini: false,
    togglerActive: false,
    thirdLevelSimple: [
      "Third level menu",
      "Just another link",
      "One last link",
    ],
    user:{
      userName: '',
      email: '',
      name: '',
      lastName: ''
    },
    userInfo: [
      {
        title: "My Profile",
        prefix: "MP",
      }
    ],
    itemsMenu: [
      {
        action: "dashboard",
        link: "/dashboard",
        title: "Dashboard",
        external: false,
      },
      {
        action: "gavel",
        link: "/incidents",
        title: "Incidentes",
        external: false,
      },
    ],
    itemsAdmin: [
      {
        action: "settings",
        active: false,
        title: "Administración",
        items: [
          {
            action: "settings",
            title: "Usuarios",
            active: false,
            link: "/admin/users" 
          },
          {
            title: "Catálogos",
            active: false,
            link: "/admin/catalogs"
          },
          {
            title: "Resultados Descarga",
            active: false,
            link: "/admin/externalDataresults"
          },
          {
            title: "Versiones",
            active: false,
            link: "/admin/versions"
          },
        ],
      },     
    ],
  }),
  computed:{
    isAdmin(){
      return loginService.isAdmin();
    }
  },
  mounted() {
    this.loadUser()
  },
  methods: {
    logout(){
      loginService.logout();
    },
    loadUser(){
      var data = loginService.getUserData()
      this.user.email = data.email;
    },
    userOptionClick(userOption){
      if(userOption.click) userOption.click()
    },
    listClose(event) {
      let items;
      let headers;
      let groups;
      let currentEl;

      if (
          document.querySelectorAll(
              ".mb-0.v-list-item.v-list-item--link.item-active"
          )
      ) {
        items = document.querySelectorAll(
            ".mb-0.v-list-item.v-list-item--link.item-active"
        );
      }

      if (
          document.querySelectorAll(
              ".mb-0.v-list-item.v-list-item--link .v-list-group__header.v-list-item--active"
          )
      ) {
        headers = document.querySelectorAll(
            ".mb-0.v-list-item.v-list-item--link .v-list-group__header.v-list-item--active"
        );
      }

      if (
          document.querySelectorAll(
              ".mb-0.v-list-item.v-list-item--link .v-list-group .v-list .v-list-group.v-list-group--active, .mb-0.v-list-item.v-list-item--link .v-list-group.v-list-group--active"
          )
      ) {
        groups = document.querySelectorAll(
            ".mb-0.v-list-item.v-list-item--link .v-list-group .v-list .v-list-group.v-list-group--active, .mb-0.v-list-item.v-list-item--link .v-list-group.v-list-group--active"
        );
      }

      if (
          event.target.closest(
              ".mb-0.v-list-item.v-list-item--link .v-list-item__content.ms-6 .v-list-group"
          )
      ) {
        currentEl = event.target.closest(
            ".mb-0.v-list-item.v-list-item--link .v-list-item__content.ms-6 .v-list-group"
        );
      }

      if (items != null) {
        for (var i = 0; i < items.length; i++) {
          items[i].classList.remove("item-active");
        }
      }

      if (headers != null) {
        for (var j = 0; j < headers.length; j++) {
          headers[j].classList.remove(
              "v-list-item--active",
              "item-active",
              "primary--text"
          );
          headers[j].setAttribute("aria-expanded", false);
        }
      }

      if (groups != null) {
        for (var k = 0; k < groups.length; k++) {
          groups[k].classList.remove("v-list-group--active", "primary--text");
        }
      }

      if (event.target.closest(".mb-0.v-list-item.v-list-item--link")) {
        event.target
            .closest(".mb-0.v-list-item.v-list-item--link")
            .classList.add("item-active");
      }

      if (currentEl != null) {
        currentEl
            .querySelector(".v-list-group__header")
            .classList.add("v-list-item--active", "item-active");
      }
    },
  },
};
</script>

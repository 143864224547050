/* eslint-disable no-unused-vars */
import Vue from "vue";
import VueRouter from "vue-router";
import DashboardLayout from "../views/Layout/DashboardLayout.vue";
import AuthCoverLayout from "../views/Layout/AuthCoverLayout";

import loginService from "@/services/login.service";

// Dashboard pages
const Dashboard = () => import("../views/Dashboard/Dashboard.vue");

// Pages
const Charts = () =>
  import(/* webpackChunkName: "pages" */ "@/views/Pages/Charts.vue");
const Alerts = () =>
  import(/* webpackChunkName: "pages" */ "@/views/Pages/Alerts.vue");
const Notifications = () =>
  import(/* webpackChunkName: "pages" */ "@/views/Pages/Notifications.vue");

Vue.use(VueRouter);

const Incidents = () => import("@/views/Pages/Incidents/Incidents");
const Login = () => import("@/views/Pages/Authentication/Login");

const AdministrationUserList = ()=> import("@/views/Pages/Administration/Users");
const AdministrationCatalogs = ()=> import("@/views/Pages/Administration/Catalogs");
const AdministrationExternalDataResults = ()=> import("@/views/Pages/Administration/ExternalDataResults");
const AdministrationVersions = ()=> import("@/views/Pages/Administration/Versions");

const routes = [
  {
    path: "/",
    name: "Dashboard",
    // redirect: "/pages/dashboards/analytics",
    component: DashboardLayout,
    children: [
      {
        path: "dashboard",
        name: "Dashboard",
        component: Dashboard,
      },
      {
        path: "incidents",
        name: "Incidencias",
        component: Incidents,
        meta: {
          groupName: "Dashboards",
        },
      },
      {
        path: "admin/users",
        name: "Usuarios",
        component: AdministrationUserList,
        meta: {
          groupName: "Administración",
        },
      },
      {
        path: "admin/catalogs",
        name: "Catálogos",
        component: AdministrationCatalogs,
        meta: {
          groupName: "Administración",
        },
      },
      {
        path: "admin/externaldataresults",
        name: "Resultados",
        component: AdministrationExternalDataResults,
        meta: {
          groupName: "Administración",
        },
      },
      {
        path: "admin/versions",
        name: "Versiones",
        component: AdministrationVersions,
        meta: {
          groupName: "Administración",
        },
      },
    ],
  },
  {
    path: "/security",
    component: AuthCoverLayout,
    children: [
      {
        path: "login",
        name: "Login",
        component: Login,
      },
    ],
  },
];

const router = new VueRouter({
  routes,
});

router.beforeEach((to, from, next) => {
  
  const publicPages = ['/security/login']
  const authRequired = !publicPages.includes(to.path)
  const loggedIn = loginService.isAuthenticated(false)

  if (authRequired && !loggedIn) {
    return next('/security/login')
  }

  next()
})

export default router;

<template>
  <div>
    <template v-if="auth">
      <v-footer
          v-if="$route.name != 'SignUpCover' && $route.name != 'Pricing'"
          class="px-6 position-absolute w-100 bottom-0"
          color="transparent"
      >
        <v-card class="flex" color="transparent">
          <v-card-text class="px-0">
            <v-row>
              <v-col cols="12" md="6">
                <div class="copyright text-body-2 ls-0 text-white">
                  © {{ new Date().getFullYear() }}, HKC.
                </div>
              </v-col>

              <v-col class="d-flex justify-end" cols="10" md="6">
                <ul
                    v-for="item in footer"
                    :key="item.linkName"
                    class="d-flex list-style-none"
                >
                  <li>
                    <a
                        :href="item.link"
                        class="
                        text-decoration-none text-white text-body-2
                        ls-0
                        no-default-hover
                      "
                        target="_blank"
                    >{{ item.linkName }}</a
                    >
                  </li>
                </ul>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-footer>

      <v-footer v-else class="mt-10 pb-5" color="transparent">
        <v-card class="flex" color="transparent">
          <v-card-text class="px-0">
            <v-row>
              <v-col class="mb-2 mx-auto text-center" cols="12" lg="8">
                <a
                    v-for="item in footer"
                    :key="item.linkName"
                    :href="item.link"
                    class="text-decoration-none text-secondary ls-0 mx-4 text-md"
                    target="_blank"
                >{{ item.linkName }}</a
                >
              </v-col>
              <v-col class="mx-auto text-center" cols="8">
                <v-btn
                    v-for="item in icons"
                    :key="item.icon"
                    :ripple="false"
                    class="mx-3 text-secondary no-default-hover"
                    href="javascript:;"
                    icon
                    link
                    target="_blank"
                    width="31"
                >
                  <v-icon size="18">{{ item.icon }}</v-icon>
                </v-btn>
              </v-col>
            </v-row>
            <v-row>
              <v-col class="mx-auto text-center" cols="8">
                <p
                    class="
                    copyright
                    text-secondary
                    font-weight-light
                    text-md
                    ls-0
                  "
                >
                  Copyright © {{ new Date().getFullYear() }}
                  Material by
                  <a
                      class="text-decoration-none btn-hover text-body ls-0"
                      href="https://www.creative-tim.com"
                      target="_blank"
                  >Creative Tim</a
                  >
                </p>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-footer>
    </template>

    <template v-else>
      <v-footer class="px-6" color="transparent">
        <v-card class="flex" color="transparent">
          <v-card-text class="px-0">
            <v-row>
              <v-col cols="12" md="6">
                <div class="copyright text-body-2 ls-0 text-muted">
                  © {{ new Date().getFullYear() }}, HKC.
                </div>
              </v-col>

              <v-col class="d-flex justify-end" cols="10" md="6">
                <ul
                    v-for="item in footer"
                    :key="item.linkName"
                    class="d-flex list-style-none"
                >
                  <li>
                    <a
                        :href="item.link"
                        class="
                        text-decoration-none text-muted text-body-2
                        ls-0
                        btn-dark-hover
                        no-default-hover
                      "
                        target="_blank"
                    >{{ item.linkName }}</a
                    >
                  </li>
                </ul>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-footer>
    </template>
  </div>
</template>
<script>
export default {
  name: "content-footer",
  props: {
    auth: Boolean,
  },
  data() {
    return {
      footer: [],
      icons: [
        {
          icon: "fab fa-dribbble",
        },
        {
          icon: "fab fa-twitter",
        },
        {
          icon: "fab fa-instagram",
        },
        {
          icon: "fab fa-pinterest",
        },
        {
          icon: "fab fa-github",
        },
      ],
    };
  },
};
</script>

import axios from 'axios'
import loginService from './login.service'

//const BASE_URL = 'https://localhost:44383'
const BASE_URL = 'https://mronoresteapiv2.azurewebsites.net'

const DEFAULT_HEADERS = {
  'Content-Type': 'application/json',
}

const LOGOUT_STATUS_CODES = [401]

export const esc = encodeURIComponent

export function e (uri) {
  return `${BASE_URL}/api/${uri}`
}

export function eauth (uri) {
  return `${BASE_URL}/${uri}`
}

axios.interceptors.response.use((response) => {
  if (LOGOUT_STATUS_CODES.includes(response.status)) {
    loginService.logout()
  }
  return response
}, (error) => {
  if (LOGOUT_STATUS_CODES.includes(error.response.status)) {
    loginService.logout()
  }

  if (error.response && error.response.data) {
    return Promise.reject(error.response.data)
  }
  return Promise.reject(error.message)
})

const http = {
  getHeaders (headers = {}) {
    axios.defaults.headers.post['Access-Control-Allow-Origin'] = '*'

    var local = {
      'x-location': localStorage.getItem('serlocale') || 'es',
    }

    var resultHeaders = { ...DEFAULT_HEADERS, ...headers, ...local }
    return resultHeaders
  },
  qs (params) {
    return (
      Object
        .keys(params)
        .map(k => esc(k) + '=' + esc(params[k]))
        .join('&')
    )
  },
  get (uri, data, headers) {
    if (data && Object.keys(data).length > 0) {
      uri = `${uri}?${this.qs(data)}`
    }

    return axios.get(uri, {
      headers: this.getHeaders(headers),
    })
  },
  post (uri, data, headers) {
    return axios.post(uri, data, {
      headers: this.getHeaders(headers),
    })
  },
  put (uri, data, headers) {
    return axios.put(uri, data, {
      headers: this.getHeaders(headers),
    })
  },
  remove (uri, headers) {
    return axios.delete(uri, {
      headers: this.getHeaders(headers),
    })
  },
  customRequest (data) {
    return axios(data)
  },
  upload (uri, data, headers) {
    return axios.post(uri, data, {
      headers: {
        'Content-Type': 'multipart/form-data',
        ...headers,
      },
    })
  },
  localPost (path, params, method, target = '_blank') {
    method = method || 'post'

    var form = document.createElement('form')
    form.setAttribute('method', method)
    form.setAttribute('action', path)
    form.setAttribute('target', target)

    for (var key in params) {
      // eslint-disable-next-line no-prototype-builtins
      if (params.hasOwnProperty(key)) {
        var hiddenField = document.createElement('input')
        hiddenField.setAttribute('type', 'hidden')
        hiddenField.setAttribute('name', key)
        hiddenField.setAttribute('value', params[key])

        form.appendChild(hiddenField)
      }
    }

    document.body.appendChild(form)
    form.submit()
  },
}

export default http
